import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { fontSize, height, lineHeight } from '@mui/system';

const components = {
	MuiButtonBase: {
		defaultProps: {
			disableRipple: true,
		},
		styleOverrides: {
			root: {
				color: "#000000"
			}
		},
	},
	MuiIconButton: {
		styleOverrides: {
			root: {
				color: "#000000"
			}
		},
	},
	MuiCheckbox: {
		styleOverrides: {
			root: {
				"&.params-table": {
					color: "white !important",
					"&.Mui-checked": {
						color: "white"
					},
					"&.MuiSvgIcon-root": {
						color: "white !important"
					}
				}
			}
		}
	},
	MuiButton: {
		styleOverrides: {
			root: {
				fontSize: "0.75rem",
				borderRadius: "8px",
				height: "34px",
				"&:focus-visible": {
					outline: "0.125rem solid #2175E2",
					outlineOffset: "0.125rem"
				},
				"& .MuiButton-startIcon": {
					marginRight: "0.375rem !important"
				},
				"&.only-svg .MuiButton-startIcon": {
					marginRight: "0rem !important"
				},
			},
			textPrimary: {
				minWidth: "99px",
				height: "34px",
				fontSize: "0.75rem",
				backgroundColor: "#000000",
				color: "#FFFFFF",
				"&.Mui-disabled": {
					background: "#7B7B7B",
					color: "#FFFFFF"
				},
				"&:hover": {
					backgroundColor: "#3A3F4B",
					color: "#FFFFFF",
				},
				"&:active": {
					backgroundColor: "#000000",
					color: "#FFFFFF",
					border: "1px #6BA2EA solid"
				}
			},
			textSecondary: {
				minWidth: "99px",
				height: "34px",
				fontSize: "0.75rem",
				backgroundColor: "#FFFFFF",
				color: "#000000",
				border: "1px solid #D8D8D8",
				"&.Mui-disabled": {
					background: "#FFFFFF",
					color: "#000000",
					opacity: "50%"
				},
				"&:hover": {
					backgroundColor: "#F7F9FC",
					color: "#000000"
				},
				"&:active": {
					backgroundColor: "#FFFFFF",
					color: "#000000",
					border: "1px #6BA2EA solid"
				}
			},
		},
		variants: [
			{
				props: { variant: 'macro-action-primary' },
				style: {
					minWidth: "10.9375rem",
					height: "2.375rem",
					fontSize: "0.8125rem",
					color: "#FFFFFF",
					backgroundColor: "#000000",
					"&.Mui-disabled": {
						background: "#7B7B7B",
						color: "#FFFFFF"
					},
					"&:hover": {
						backgroundColor: "#3A3F4B",
						color: "#FFFFFF",
					},
					"&:active": {
						backgroundColor: "#000000",
						color: "#FFFFFF",
						border: "1px #6BA2EA solid"
					}
				},
			},
			{
				props: { variant: 'macro-action-secondary' },
				style: {
					minWidth: "10.9375rem",
					height: "2.375rem",
					fontSize: "0.8125rem",
					backgroundColor: "#FFFFFF",
					color: "#000000",
					border: "1px solid #D8D8D8",
					"&.Mui-disabled": {
						background: "#FFFFFF",
						color: "#000000",
						opacity: "50%"
					},
					"&:hover": {
						backgroundColor: "#F7F9FC",
						color: "#000000"
					},
					"&:active": {
						backgroundColor: "#FFFFFF",
						color: "#000000",
						border: "1px #6BA2EA solid"
					}
				},
			},
			{
				props: { variant: 'light-blue-action' },
				style: {
					height: "2.375rem",
					padding: "0.125rem 0.75rem",
					fontSize: "0.75rem",
					lineHeight: "0.875rem",
					fontWeight: "600",
					color: "#0D0C22",
					backgroundColor: "#F0F5FD",
					border: "1px solid #6BA2EA",
					"&.Mui-disabled": {
						color: "#0D0C22",
						backgroundColor: "#F0F5FD",
						border: "1px solid #6BA2EA",
						opacity: "50%"
					},
					"&:hover": {
						color: "#0D0C22",
						backgroundColor: "#CFE2F8",
						border: "1px solid #6BA2EA"
					},
					"&:active, &.open-dialog": {
						color: "#0D0C22",
						backgroundColor: "#CFE2F8",
						border: "1px solid #6BA2EA"
					},
					"&:focus": {
						color: "#0D0C22",
						backgroundColor: "#F0F5FD",
						border: "1px #134382 solid"
					}
				},
			},
			{
				props: { variant: 'dark-blue-action' },
				style: {
					height: "2.375rem",
					padding: "0.125rem 0.75rem",
					fontSize: "0.75rem",
					lineHeight: "0.875rem",
					fontWeight: "600",
					color: "#FFFFFF",
					backgroundColor: "#134382",
					border: "1px solid #134382",
					"&.Mui-disabled": {
						color: "#FFFFFF",
						backgroundColor: "#134382",
						border: "1px solid #134382",
						opacity: "50%"
					},
					"&:hover": {
						color: "#FFFFFF",
						backgroundColor: "#2E5FA0",
						border: "1px solid #2E5FA0"
					},
					"&:active, &.open-dialog": {
						color: "#FFFFFF",
						backgroundColor: "#2E5FA0",
						border: "1px solid #2E5FA0"
					},
					"&:focus": {
						color: "#FFFFFF",
						backgroundColor: "#134382",
						border: "1px #2175E2 solid"
					}
				},
			},
			{
				props: { variant: 'modal-primary' },
				style: {
					minWidth: "10.4375rem",
					height: "2.375rem",
					fontSize: "0.8125rem",
					color: "#FFFFFF",
					backgroundColor: "#000000",
					"&.Mui-disabled": {
						background: "#7B7B7B",
						color: "#FFFFFF"
					},
					"&:hover": {
						backgroundColor: "#3A3F4B",
						color: "#FFFFFF",
					},
					"&:active": {
						backgroundColor: "#000000",
						color: "#FFFFFF",
						border: "1px #2175E2 solid",
					}
				},
			},
			{
				props: { variant: 'modal-secondary' },
				style: {
					minWidth: "10.4375rem",
					height: "2.375rem",
					fontSize: "0.8125rem",
					backgroundColor: "#FFFFFF",
					color: "#000000",
					border: "1px solid #D8D8D8",
					"&.Mui-disabled": {
						background: "#FFFFFF",
						color: "#000000",
						opacity: "50%"
					},
					"&:hover": {
						backgroundColor: "#F7F9FC",
						color: "#000000"
					},
					"&:active": {
						backgroundColor: "#FFFFFF",
						color: "#000000",
						border: "1px #6BA2EA solid"
					}
				},
			},
			{
				props: { variant: 'copy-to-clipboard' },
				style: {
					// boxSizing: "content-box",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					height: "fit-content",
					// width: "100%",
					maxWidth: "100%",
					padding: "0.2rem 0.5rem",
					color: "rgba(0, 0, 0, 0.87)",
					backgroundColor: "transparent",
					border: "none",
					userSelect: "text",
					fontFamily: "Roboto Mono",
					fontSize: "0.75rem",
					lineHeight: "200%",
					fontWeight: "500",
					textAlign: "left",
				},
			},
			{
				props: { variant: 'tooltip' },
				style: {
					padding: "0",
					minWidth: "0",
					color: "#000000",
					backgroundColor: "transparent",
					border: "none",
					userSelect: "none",
					fontFamily: "Roboto Mono",
					textAlign: "left",
					"&:hover": {
						background: "transparent"
					},
				}
			},
			{
				props: { variant: 'console-action' },
				style: {
					height: "100%",
					minWidth: "fit-content",
					borderRadius: 0,
					// borderTopLeftRadius: "8px",
					borderRight: " #5A5877 solid 1px",
					borderLeft: " #5A5877 solid 1px",
					color: "#F0F5FD",
					backgroundColor: "transparent",
					fontFamily: "Roboto Mono",
					textWeight: 500,
					"&:hover": {
						background: "transparent"
					},
					"&.Mui-disabled": {
						color: "#D8D8D8"
					},
				}
			},
			{
				props: { variant: 'console-tab' },
				style: {
					height: "100%",
					minWidth: "fit-content",
					borderRadius: 0,
					// borderTopLeftRadius: "8px",
					// borderRight: " #5A5877 solid 1px",
					color: "#F0F5FD",
					backgroundColor: "transparent",
					fontFamily: "Roboto Mono",
					textWeight: 500,
					"&:hover": {
						background: "transparent",
						textDecoration: "underline"
					},
					"&.Mui-disabled": {
						color: "#D8D8D8"
					},
				}
			},
			{
				props: { variant: 'console-history' },
				style: {
					height: "100%",
					borderRadius: 0,
					borderTopRightRadius: "8px",
					borderLeft: " #5A5877 solid 1px",
					color: "#F0F5FD",
					backgroundColor: "transparent",
					fontFamily: "Roboto Mono",
					textWeight: 500,
					"&:hover": {
						background: "transparent"
					},
					"&.Mui-disabled": {
						color: "#D8D8D8"
					},
				}
			},
			{
				props: { variant: 'console-command' },
				style: {
					height: "100%",
					borderRadius: 0,
					borderRight: " #5A5877 solid 1px",
					textDecoration: "underline",
					color: "#F0F5FD",
					backgroundColor: "transparent",
					userSelect: "none",
					fontFamily: "Roboto Mono",
					textWeight: 500,
					"&:hover": {
						background: "transparent"
					},
					"&.Mui-disabled": {
						color: "#D8D8D8"
					},
				}
			},
			{
				props: { variant: 'simple-text' },
				style: {
					height:"unset",
					boxSizing: "border-box",
					fontSize: "0.75rem",
					lineHeight: "0.875rem",
					fontWeight: "600",
					borderRadius: "1rem",
					padding: "0.5rem",
					color: "black",
					background: "#F0F5FD",
					"&:hover": {
						background: "#F7F9FC"
					},
					"&:active": {
						background: "#F0F5FD",
						border: "solid #6BA2EA 1px"
					},
					"&.Mui-disabled": {
						color: "#D8D8D8"
					},
				}
			},
			{
				props: { variant: 'simple-text-warning' },
				style: {
					height:"unset",
					boxSizing: "border-box",
					fontSize: "0.75rem",
					lineHeight: "0.875rem",
					fontWeight: "600",
					borderRadius: "1rem",
					padding: "0.5rem",
					background: "#fffd8d",
					border: "none",
					"&:hover": {
						background: "#fdfa72"
					},
					"&:active": {
						background: "#fffec8",
						// border: "solid #fdfa72 1px"
					},
					"&.Mui-disabled": {
						color: "#D8D8D8"
					},
				}
			}
		]
	},
	MuiLink: {
		defaultProps: {
			underline: "hover",
		},
	},
	MuiCardHeader: {
		defaultProps: {
			titleTypographyProps: {
				fontSize: "1.25rem",
				fontWeight: "bold"
			},
		},
		styleOverrides: {
			root: {
				backgroundColor: "FFF",
				color: "#0D0C22",
				paddingLeft: 0
			},
			action: {
				marginTop: "-4px",
				marginRight: "-4px",
			},
		},
	},
	MuiCard: {
		styleOverrides: {
			root: {
				borderRadius: "6px",
				backgroundImage: "none",
			},
		},
	},
	MuiPaper: {
		styleOverrides: {
			root: {
				color: "#000000",
				backgroundImage: "none",
			},
		},
	},
	MuiLinearProgress: {
		styleOverrides: {
			colorPrimary: {
				backgroundColor: '#CFE2F8',
			},
			colorSecondary: {
				backgroundColor: 'transparent',
			},
			barColorPrimary: {
				backgroundColor: '#F5F5F6',
			},
			dashedColorPrimary: {
				backgroundColor: '#FFFFFF',
				backgroundImage: "radial-gradient(rgb(87, 143, 218) 0%, rgb(87, 143, 218) 16%, transparent 42%)",
			}
		}
	},
	MuiCircularProgress: {
		styleOverrides: {
			colorPrimary: {
				color: '#CFE2F8',
			}
		}
	},
	MuiPickersDay: {
		styleOverrides: {
			day: {
				fontWeight: "300",
			},
		},
	},
	MuiPickersYear: {
		styleOverrides: {
			root: {
				height: "64px",
			},
		},
	},
	MuiPickersCalendar: {
		styleOverrides: {
			transitionContainer: {
				marginTop: "6px",
			},
		},
	},
	MuiPickersCalendarHeader: {
		styleOverrides: {
			iconButton: {
				backgroundColor: "transparent",
				"& > *": {
					backgroundColor: "transparent",
				},
			},
			switchHeader: {
				marginTop: "2px",
				marginBottom: "4px",
			},
		},
	},
	MuiPickersClock: {
		styleOverrides: {
			container: {
				margin: `32px 0 4px`,
			},
		},
	},
	MuiPickersClockNumber: {
		styleOverrides: {
			clockNumber: {
				left: `calc(50% - 16px)`,
				width: "32px",
				height: "32px",
			},
		},
	},
	MuiPickerDTHeader: {
		styleOverrides: {
			dateHeader: {
				"& h4": {
					fontSize: "2.125rem",
					fontWeight: 400,
				},
			},
			timeHeader: {
				"& h3": {
					fontSize: "3rem",
					fontWeight: 400,
				},
			},
		},
	},
	MuiPickersTimePicker: {
		styleOverrides: {
			hourMinuteLabel: {
				"& h2": {
					fontSize: "3.75rem",
					fontWeight: 300,
				},
			},
		},
	},
	MuiPickersToolbar: {
		styleOverrides: {
			toolbar: {
				"& h4": {
					fontSize: "2.125rem",
					fontWeight: 400,
				},
			},
		},
	},
	MuiTableRow: {
		styleOverrides: {
			root: {
				background: "transparent !important"
			}
		}
	},
	MuiTableCell: {
		styleOverrides: {
			root: {
				overflow: "hidden",
				boxSizing: "border-box",
				height: "3.75rem",
				maxHeight: "3.75rem",
				paddingTop: "1rem",
				paddingBottom: "1rem",
				paddingRight: "2rem",
				paddingLeft: "0.25rem",
				fontFamily: "Roboto Mono",
				color: "#5A5877",
				fontSize: "0.875rem",
				fontWeight: "400",
				background: "transparent !important",
				color: "#000000",
				borderBottom: "1px #D8D8D8 solid",
			},
			head: {
				fontFamily: "SwissIntl",
				fontWeight: "500",
				background: "white !important",
			}
		}
	},
	MuiTablePagination: {
		defaultProps: {
			labelRowsPerPage: "Rows per page"
		},
		styleOverrides: {
			root: {
			},
			selectLabel: {
				fontSize: "0.875rem",
				fontWeight: 600,
				marginRight: 0
			},
			selectRoot: {
				width: "5.0625rem",
				height: "2.375rem",
				border: "1px #D8D8D8 solid",
				borderRadius: "2.375rem",
				padding: "0",
				marginRight: 0,
				fontWeight: 500,
				"& div": {
					width: "100%",
					textAlignLast: "left !important",
					"&:focus": {
						background: "transparent"
					}
				},
			},
			selectIcon: {
				color: "#5A5877",
				strokeWidth: 1,
				right: "6px"
			},
			actions: {
				"& button": {
					background: "#6BA2EA33",
					padding: 0,
					width: "24px",
					height: "24px",
					margin: "0 4px",
					"& svg": {
						width: "16px",
						height: "16px"
					}
				}
			},
			displayedRows: {
				marginLeft: "16px"
			}

		},
	},
	MuiToolbar: {
		styleOverrides: {
			root: {
				fontSize: "0.875rem",
				fontWeight: 500,
			},
		},
	},
	MuiTextField: {
		styleOverrides: {
			root: {
				"&.subvariant-modal-form .MuiOutlinedInput-root": {
					background: "white",
					"& fieldset": {
						borderColor: "#D8D8D8",
						"& legend": {
							"& span": {
								display: "block"
							}
						}
					},
				},
			}
		},
		defaultProps: {
			inputProps: {
				style: {
					// paddingTop: '0',
				},
			},
		}
	},
	MuiInputBase: {
		styleOverrides: {
			root: {
				color: "#000000",
				"&.console svg": {
					fill: "white",
				}
			}
		},
	},
	MuiFormHelperText: {
		styleOverrides: {
			root: {
				marginLeft: "4px",
				marginRight: "4px",
				fontSize: "0.625rem",
			}
		}
	},
	MuiInputLabel: {
		styleOverrides: {
			root: {
				userSelect: "text !important",
				color: "#000000",
				lineHeight: "120%",
				fontSize: "0.875rem !important",
				position: "relative",
				fontWeight: "600 !important",
				paddingBottom: "0.75rem",
				transform: "none",
				"&.Mui-focused": {
					color: "#000000"
				},
			}
		}
	},
	MuiFormLabel: {
		styleOverrides: {
			root: {
				color: "#000000",
				fontSize: "0.875rem !important",
				position: "relative",
				fontWeight: "600 !important",
				paddingBottom: "0px",
				transform: "none",
				"&.Mui-focused": {
					color: "#000000"
				},
			}
		}
	},
	MuiOutlinedInput: {
		styleOverrides: {
			root: {
				fontSize: "0.875rem !important",
				fontWeight: 400,
				lineHeight: "155%",
				height: "2.375rem",
				borderRadius: "0.5rem",
				"& fieldset": {
					"& legend": {
						"& span": {
							display: "none"
						}
					}
				},
				"& input": {
					padding: "0"
				},
				"& .MuiSelect-select": {
					padding: 0
				},
				"&.MuiInputBase-root": {
					padding: "0.75rem !important"
				},
				"&.MuiInputBase-multiline": {
					padding: "0.75rem"
				},
				"&.Mui-focused fieldset": {
					borderColor: "#2175E2 !important",
				},
				"&.setting-input": {
					height: "2.125rem",
					borderRadius: "0.5rem"
				},
				"&.params-table": {
					height: "2.125rem",
					borderRadius: "0.5rem",
					color: "white",
					"& fieldset": {
						borderColor: "white",
					},
					"&:hover fieldset": {
						borderColor: "#B3CAE9",
					},
					"&.Mui-focused fieldset": {
						borderColor: "#5A5877",
					}
				},
			},
		}
	},
	MuiAutocomplete: {
		styleOverrides: {
			root: {
				"& .MuiInput-root": {
					paddingRight: "2.7rem !important"
				},
				"& .MuiOutlinedInput-root.MuiInputBase-root": {
					paddingRight: "3.8rem !important",
				},
				"&.console .MuiFormHelperText-root.Mui-error": {
					color: "#FEC36A",
				},
			}
		},
	},
	MuiSelect: {
		defaultProps: {
			IconComponent: KeyboardArrowDownIcon
		}
	},
	MuiMenuItem: {
		styleOverrides: {
			root: {
				// paddingRight: 5,
				// paddingLeft: 5,
				// borderRadius: "0.5rem"
			}
		}
	},
	MuiPopover: {
		styleOverrides: {
			root: {
				'.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper': {
					maxHeight: '300px',
					border: "solid #D8D8D8 1px",
					// border: "solid #2175E2 0.0625rem",
					// background: "#F7F9FC",
					padding: "0px",
					marginTop: "0.5rem",
				},
				'.MuiMenu-list': {
					padding: "0px"
				}
			}
		}
	},
	MuiDialogContent: {
		styleOverrides: {
			root: {
				// height: "100%",
				// padding: "8px"
			}
		}
	},
	MuiDialogTitle: {
		styleOverrides: {
			root: {
				fontSize: "1rem",
				lineHeight: 1.25,
				fontWeight: "500",
				paddingBottom: "8px",
			}
		}
	},
	MuiDialogContentText: {
		styleOverrides: {
			root: {
				color: "#000000",
				fontSize: "0.875",
				fontWeight: "500",
				paddingBottom: "8px",
			}
		}
	},
	MuiDialog: {
		styleOverrides: {
			root: {
				// zIndex: "1280",
			},
			paper: {
				color: "#000000",
				width: "24.875rem",
				borderRadius: "1rem",
				"&.fit-modal": {
					maxWidth: "none",
					width: "fit-content",
				},
				"&.small-modal": {
					maxWidth: "none",
					width: "30.375rem",
				},
				"&.medium-modal": {
					maxWidth: "none",
					width: "37.5rem",
				},
				"&.large-modal": {
					maxWidth: "none",
					width: "64rem",
				},
				"&.extra-large-modal": {
					maxWidth: "none",
					width: "90rem",
					height: "100%"
				}
			}
		}
	},
	MuiListItemText: {
		defaultProps: {
			primaryTypographyProps: {
				fontWeight: 500,
			},
			secondaryTypographyProps: {
				color: "#7B7B7B"
			}
		}
	}
};

export default components;
