import Iron from "@hapi/iron";
import { MAX_AGE, setTokenCookie, getTokenCookie } from "./auth-cookies";
import { logThis } from "@lib/utils";

const TOKEN_SECRET = process.env.TOKEN_SECRET;

export async function setLoginSession(res, session) {
  const createdAt = Date.now();
  // Create a session object with a max age that we can validate later
  const obj = { ...session, createdAt, maxAge: MAX_AGE };
  const token = session.message; //JSON.stringify(obj); //await Iron.seal(obj, TOKEN_SECRET, Iron.defaults);

  setTokenCookie(res, token);
}

export async function getLoginSession(req) {
  const token = getTokenCookie(req);
  if (!token) {
    //Session token doesn't exist
    logThis("getLoginSession Session token doesn't exist");
    //return undefined
    return;
  }
  //try to decode session token
  try {
    const session = token; //JSON.parse(token); //await Iron.unseal(token, TOKEN_SECRET, Iron.defaults);
    const expiresAt = session.createdAt + session.maxAge * 1000;
    // Validate the expiration date of the session
    // if (Date.now() > expiresAt) {
    //   logThis("getLoginSession Session expired");
    //   //return undefined
    //   return
    // }
    return session;
  } catch (error) {
    //failing decoding session token
    logThis("getLoginSession error decoding cookie | " + "Error name : " + error.name + "| Error message : " + error.message + " | Error status: " + error.status);
    // return undefined
    return
  }
}


