//react components
import React, { useState, useEffect } from "react";
//next.js
import { useRouter } from 'next/router';
//sqlitecloud lib
import { fetchApiRoute, getExecutionAmbient, disableSendMail } from "@lib/utils";
const useSendMail = () => {
	//sending state
	const [sendingResult, setSendingResult] = useState(null);
	const [sendingError, setSendingError] = useState(null);
	//get actual routing parameters
	const router = useRouter();
	const path = router.asPath;
	const query = router.query;
	const sendMail = async (error, source = "any") => {
		//get the domain from where the mail is sent
		let ambient = getExecutionAmbient();
		//build message
		const subject = `Error Network ❌ | ${ambient} | SQLite Cloud Dashboard`;
		const html = `
<h2>Error</h2>
	<ul>
		<li>
			<p>Description: ${error.toString()}</p>
		</li>
		<li>
			<p>Details: ${JSON.stringify(error, null, 2)}</p>
		</li>
		<li>
			<p>Url:${window.location.href}</p>
		</li>
		<li>
			<p>Path: ${path}</p>
		</li>
		<li>
			<p>Query: ${JSON.stringify(query, null, 2)}</p>
		</li>
	</ul>
`
		//build body
		const body = {
			subject: subject,
			html: html,
			type: "logs"
		}
		//try send message
		const disable = disableSendMail();
		if (!disable) {
			try {
				const opt = {
					method: "POST",
					endpoint: "/api/sendMail",
					endpointCallLocation: "Sending mail",
					body: body
				}
				const result = await fetchApiRoute(opt);
				setSendingResult(result);
			} catch (error) {
				console.log(error);
				setSendingError(error);
			}
		}
	}

	return { sendingResult, sendingError, sendMail };
}


export {
	useSendMail
}