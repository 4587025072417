//react components
import { useEffect } from "react";
//sqlitecloud hook
import { useSetSnackNotification } from "@custom-hooks/useSetSnackNotification";
import { useSendMail } from "./useSendMail";
//sqlitecloud components
import ErrorNotificaion from "@generic-components/ErrorNotification";

const useSetError = (error, source = "any") => {
	//get method to update snack notification
	const { createSnackNotification } = useSetSnackNotification();
	//get method to send mail
	const { sendingResult, sendingError, sendMail } = useSendMail();
	useEffect(() => {
		//sqlitecloud user
		if (error) {
			const newError = <ErrorNotificaion error={error} />
			/**
			 * status undefined --> called failed because it was cancelled

			 * status 530 --> timeout error generated by next.js server
			 * status 533 --> errors not handled by backend or next.js or gateway
			  
			 * status 400 --> errors handled by backend
			 * status 401 --> errors handled by backend
			 * status 403 --> errors handled by backend
			 * status 404 --> errors handled by backend
			 * status 405 --> errors handled by backend
			 * status 409 --> errors handled by backend
			 * status 500 --> errors handled by backend
			 * status 501 --> errors handled by backend
			 * status 502 --> errors handled by backend, bad gateway
			 * status 504 --> errors handled by backend
			*/
			const errorStatus = error.status ? parseInt(error.status) : undefined;
			//define when send error mail notification
			const sendCondition =
				errorStatus
				&& errorStatus !== 400
				&& errorStatus !== 401
				&& errorStatus !== 403
				&& errorStatus !== 404
				&& errorStatus !== 405
				&& errorStatus !== 409
				&& errorStatus !== 500
				&& errorStatus !== 501
				&& errorStatus !== 502
				&& errorStatus !== 504;
			if (sendCondition) {
				sendMail(error, source);
			}
			//define when show error notificaion
			const notificationCondition =
				errorStatus
				&& errorStatus !== 401;
			if (notificationCondition) {
				createSnackNotification({
					type: "error",
					component: newError
				})
			}
		}
	}, [error, source])
}

export { useSetError }