//react
import React, { createContext, useEffect, useState } from "react";
//next.js
import { useRouter } from 'next/router';

const StateContext = createContext();

function StateProvider({ children }) {
	//browser tab unique id
	const [tabId, setTabId] = useState(null);
	//read query parameters from url
	const { query } = useRouter();
	//tracking
	const [formbricksInit, setFormbricksInit] = useState(false);
	//user info related states
	const [userInfo, setUserInfo] = useState(null);
	const [simulateGenericUser, setSimulateGenericUser] = useState(true);
	//restore from local storage simulateGenericUser state
	useEffect(() => {
		if (userInfo) {
			var retrievedData = localStorage.getItem(`${userInfo.company}-${userInfo.id}`);
			try {
				var simulateGenericUser = JSON.parse(retrievedData);
				if (simulateGenericUser === false || simulateGenericUser === true) {
					setSimulateGenericUser(simulateGenericUser);
				} else {
					setSimulateGenericUser(true);
				}
			} catch (error) {
				setSimulateGenericUser(true);
			}
		} else {
			setSimulateGenericUser(true);
		}
	}, [userInfo])
	//projects related
	const [userProjects, setUserProjects] = useState(null);
	const [actualProjectIndex, setActualProjectIndex] = useState(-1);
	const [dashboardItems, setDashboardItems] = useState(null);
	//handle JobsDropDown visualitazion state
	const [isOpenJobsDropDown, setIsOpenJobsDropDown] = useState(query.firstProject == "true" ? true : false);
	const [jobsRefreshInterval, setJobsRefreshInterval] = useState(false);
	//handle snack notifications
	const [snackNotifications, setSnackNotifications] = useState([]);
	//handle loading
	const [loadingNotifications, setLoadingNotifications] = useState([]);
	//return
	return (
		<StateContext.Provider
			value={{
				tabId, setTabId,
				formbricksInit, setFormbricksInit,
				userInfo, setUserInfo,
				simulateGenericUser, setSimulateGenericUser,
				dashboardItems, setDashboardItems,
				userProjects, setUserProjects,
				actualProjectIndex, setActualProjectIndex,
				isOpenJobsDropDown, setIsOpenJobsDropDown,
				jobsRefreshInterval, setJobsRefreshInterval,
				snackNotifications, setSnackNotifications,
				loadingNotifications, setLoadingNotifications
			}}>
			{children}
		</StateContext.Provider>
	);
}

export { StateProvider, StateContext };
