import { THEMES } from "@constants";


const sqlitecloud = {
	name: THEMES.SQLITECLOUD,
	palette: {
		mode: "light",
		primary: {
			main: "#6BA2EA",
			white: "#FFFFFF",
			whiteBis: "#FEFEFE",
			black: "#000000",
		},
		secondary: {
			main: "#2175E2",
			accent1: "#CFE2F8",
			accent2: "#134382",
			accent3: "#5A5877",
			accent4: "#0D0C22",
			accent5: "#3A3F4B",
			accent6: "#F1FBFF",
			accent7: "#C3D7F2",
			accent8: "#B3CAE9",
			accent9: "#9EC6FC",
			accent10: "#9AC0F1",
			accent11: "#4AA480",
			accent12: "#F8CFCF",
			accent13: "#FFD0D0",
			accent14: "#F3CFF8",
		},
		neutral: {
			cream: "#F7F9FC",
			pale: "#F0F5FD",
			darkGrey: "#7B7B7B",
			darkGreyBis: "#898989",
			lightGrey: "#D8D8D8",
			lightestGrey: "#F5F5F6",
		},
		dark: {
			night: "#0C0C0C"
		},
		background: {
			default: "#FFF",
			paper: "#FFF",
			fontWeight: "bold",
			textTransform: "uppercase",
			letterSpacing: "0.08rem"
		},
		info: {
			main: "#007BB0",
			light: "#9AC0F1"
		},
		success: {
			main: "#38C149",
			light: "#4CC75B",
			dark: "#679F00"
		},
		warning: {
			main: "#E2740E",
			light: "#FEC36A"
		},
		error: {
			main: "#B00020",
			light: "#E5374C"
		},
	}
};

const variants = [
	sqlitecloud
];

export default variants;
