import * as React from "react";
import styled from "@emotion/styled";
import { LightAsync as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/cjs/styles/hljs";

const Base = styled(SyntaxHighlighter)`
  max-height: 12rem;
  border-radius: 3px;
  padding: ${(props) => props.theme.spacing(3)} !important;
  background-color: ${(props) => props.theme.palette.dark.night} !important;
`;

const Code = ({ children }) => {
  return (
    <Base language="javascript" style={vs2015}>
      {children}
    </Base>
  );
};

export default Code;
