const analyzeError = (error) => {
	const result = {
		reportError: true,
		retry: true
	}
	//extract if preset error info
	const errorInfo = error.info ? error.info : undefined;
	if (errorInfo && errorInfo.endpoint && errorInfo.message) {
		const endpoint = errorInfo.endpoint;
		const message = errorInfo.message;
		//filter errors for backups endpoint
		// const backupsEndpointRegex = /\/v1\/[a-zA-Z0-9]+\/backups/;
		// if (backupsEndpointRegex.test(endpoint)) {
		// 	if (message.includes("An error occurred while looking for the node's address")) {
		// 		result.reportError = false;
		// 		result.retry = false;
		// 	}
		// }
	}
	return result;
}

export default analyzeError;