//react components
import { useContext, useState, useEffect } from "react";
//mui componentes
import { useTheme } from '@mui/styles';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
//sqlitecloud components
import Code from "@components/Code";
//sqlitecloud componenets
import { useCopyToClipboard } from "@custom-hooks/useCopyToClipboard";

const ErrorNotificaion = ({ error }) => {
	//theme
	const theme = useTheme();
	//handle hide or show detail
	const [showDetail, setShowDetail] = useState(false);
	const handleDetailClick = () => {
		setShowDetail(!showDetail)
	}
	//handle error visualization and copu
	const errorDetail = JSON.stringify(error, null, 2);
	//get method to copy to clipboard
	const { copyToClipboard } = useCopyToClipboard();
	//render
	return (
		<Box>
			<Typography variant="13px-reg" pb={2}><b>Error</b></Typography><br />
			<Typography variant="12px-reg" fontFamily={"Roboto Mono"}>{error.toString()}</Typography><br />
			<Box
				mt={2}
				p={2}
				sx={{
					display: showDetail ? "block" : "none",
					fontFamily: "Roboto Mono",
					fontSize: "0.75rem",
					border: `solid ${theme.palette.neutral.lightestGrey} 0.125rem`
				}}>
				<Typography as="div" onClick={() => copyToClipboard(errorDetail)} variant="13px-bold" sx={{ cursor: "pointer", color: theme.palette.secondary.main, fontWeight: "600", marginBottom: 2 }}>Copy error</Typography>
				<Code>{errorDetail}</Code>
			</Box>
			<Typography as="div" onClick={handleDetailClick} variant="13px-bold" mt={3} sx={{ cursor: "pointer", color: theme.palette.secondary.main }}>{showDetail ? "Hide details" : "Show details"}</Typography>
		</Box >

	)
}

export default ErrorNotificaion;