//posthog
import posthog from "posthog-js";
//date-fns
import { format } from 'date-fns';
//sqlitecloud lib
import { getLoginSession } from "@lib/auth";
//axsios
const axios = require('axios');
//sqlitecloud env
const DASHBOARD_API_URL = process.env.DASHBOARD_API_URL;
const API_API_URL = process.env.API_API_URL;
const API_TIMEOUT = process.env.API_TIMEOUT;
const UPGRADING_INFRA = process.env.NEXT_PUBLIC_UPGRADING_INFRA;
const DISABLE_SEND_MAIL = process.env.NEXT_PUBLIC_DISABLE_SEND_MAIL;


//console log improved with timestamp
const logThis = (msg) => {
	let prefix = format(new Date(), 'yyyy/MM/dd HH:mm:ss.SSS');
	console.log("!!!!!!!!! " + prefix + " - " + msg);
}

//delay function
const delay = (milliseconds) => {
	return new Promise(resolve => {
		setTimeout(resolve, milliseconds);
	});
}

//obj is empty
function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) {
			return false;
		}
	}
	return true;
}

//get the execution ambient of the dashbaord
function getExecutionAmbient() {
	const url = new URL(window.location.href);
	let ambient = "UNKNOWN";
	if (url.hostname.includes("local")) {
		ambient = "LOCALHOST";
	} else if (url.hostname === "staging.sqlitecloud.io") {
		ambient = "STAGING";
	} else if (url.hostname === "dashboard.sqlitecloud.io") {
		ambient = "PRODUCTION";
	} else if (url.hostname.includes("wip")) {
		ambient = url.hostname.split(".")[0].toUpperCase();
	}
	return ambient;
}

//check if send debug mail is enabled or not
function disableSendMail() {
	if (
		UPGRADING_INFRA.toLocaleLowerCase() === "true" ||
		DISABLE_SEND_MAIL.toLocaleLowerCase() === "true"
	) {
		return true;
	} else {
		return false;
	}
}

//get UTC offset
function getUtcOffset() {
	// Create a new Date object
	const date = new Date();

	// Get the time zone offset in minutes
	const timezoneOffsetInMinutes = date.getTimezoneOffset();

	// The offset is positive if the local timezone is behind UTC and negative if it is ahead of UTC
	const offsetHours = Math.floor(Math.abs(timezoneOffsetInMinutes) / 60);
	const offsetMinutes = Math.abs(timezoneOffsetInMinutes) % 60;
	const sign = timezoneOffsetInMinutes > 0 ? "-" : "+";

	// Format the offset as a string
	const utcDifference = `UTC${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;

	return utcDifference;
}

//convert a date to utc 0
const convertDateToUtc0 = (inputDate) => {
	const date = new Date(inputDate);
	const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
	const outputString = format(utcDate, 'yyyy-MM-dd HH:mm:ss');
	return outputString;
}

//convert a utc0 date to user local time zone
const convertUtc0ToLocalTimeZone = (inputDate, dateFormat = "yyyy-MM-dd HH:mm:ss", appendUtc = false) => {
	const date = new Date(inputDate);
	const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
	const outputString = format(utcDate, dateFormat);
	if (appendUtc) {
		outputString = `${outputString} (${getUtcOffset()})`;
	}
	return outputString;
}

//compare dates
function compareDateToToday(dateString) {
	// Parse the date strings into Date objects
	const date1 = new Date(dateString);
	const date2 = new Date();
	// Compare the dates
	if (date1 > date2) {
		return "greater";
	} else if (date1 < date2) {
		return "earlier";
	} else {
		return "Dates are not comparable";
	}
}

//validate strings
const validateString = (str) => {
	var strLastChr = str.slice(-1);
	var strOnlyLettersAndNumbersPointLine = /^[A-Za-z0-9.-]*$/.test(str);
	var strLastChrOnlyLettersAndNumbers = /^[A-Za-z0-9]*$/.test(strLastChr);
	let returnValue = {
		valid: strOnlyLettersAndNumbersPointLine && strLastChrOnlyLettersAndNumbers,
		messages: [],
	}
	if (!strOnlyLettersAndNumbersPointLine) {
		returnValue.messages.push("allowed characters (a-z, A-Z, 0-9, . and -)");
	}
	if (!strLastChrOnlyLettersAndNumbers) {
		returnValue.messages.push("last character must be one of (a-z, A-Z, 0-9)");
	}
	return returnValue;
}


//validate env variable
const validateEnvVariable = (envValue) => {
	//check if the name starts with a digit
	if (/^\d/.test(envValue)) {
		return {
			valid: false,
			messages: ["The name contains invalid characters. Only letters, digits, and underscores are allowed. Furthermore, the name should not start with a digit."]
		};
	}
	//check if the name contains only letters, digits, and underscores
	if (/^[A-Za-z0-9_]+$/.test(envValue)) {
		return {
			valid: true,
			messages: []
		};
	}
	return {
		valid: false,
		messages: ["The name contains invalid characters. Only letters, digits, and underscores are allowed. Furthermore, the name should not start with a digit."]
	};
}

//get a string and remove exatra space and trim it
function removeExtraSpaceAndTrimString(string) {
	const trimString = string.trim();
	const stringWithoutExtraSpaces = trimString.replace(/\s+/g, ' ');
	return stringWithoutExtraSpaces;
}

/** Simple hash, not for cryptographic use, just to make user keys unreadable */
function hashString(input) {
	let hash = 5381
	for (let i = 0; i < input.length; i++) {
		hash = (hash << 5) + hash + input.charCodeAt(i) /* hash * 33 + c */
	}
	return hash.toString(16)
}

/** Generate a cryptographically safe unique id with an optional prefix */
const generateId = (prefix = undefined, numberOfDigits = 12) => {
	const customAlphabet = '1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
	let id = ''
	for (let i = 0; i < numberOfDigits; i++) {
		id += customAlphabet.charAt(Math.floor(Math.random() * customAlphabet.length))
	}
	return prefix ? prefix + id : id
}

//validate strings containing only characters that do not need encoding in URL with minimun length
const validateStringnonEncodingCharsInUrl = (string, minLength = 6, allowedChar = true) => {
	const validation = { valid: true, messages: [] };

	// Check if the string is at least minLength characters long
	if (string.length < minLength) {
		validation.valid = false;
		validation.messages.push(`must be at least ${minLength} characters long`);
	}

	if (allowedChar) {
		// Check if the password contains only characters that do not need encoding
		// Here, we're using a regular expression to match characters that do not need encoding
		// You may need to adjust this regular expression based on your specific requirements
		var nonEncodingCharsRegex = /^[a-zA-Z0-9-_.~]+$/;
		if (!nonEncodingCharsRegex.test(string)) {
			validation.valid = false;
			validation.messages.push("allowed characters (a-z, A-Z, 0-9, and  -  .  _  ~ )");
		}
	}

	return validation;
}

//capitalize first letter
function capitalizeFirstLetter(str) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

//remove query string from url
function removeQueryString(url) {
	const queryStringIndex = url.indexOf('?');
	if (queryStringIndex !== -1) {
		return url.slice(0, queryStringIndex);
	}
	return url;
}


//takes a generic value and escapes it
function escapeParameter(param) {
	if (param === null || param === undefined) {
		return 'NULL'
	}

	if (typeof param === 'string') {
		// replace single quote with two single quotes
		param = param.replace(/'/g, "''")
		return `'${param}'`
	}

	if (typeof param === 'number') {
		return param.toString()
	}

	if (typeof param === 'boolean') {
		return param ? '1' : '0'
	}

	// serialize buffer as X'...' hex encoded string
	if (Buffer.isBuffer(param)) {
		return `X'${param.toString('hex')}'`
	}

	if (typeof param === 'object') {
		// serialize json then escape single quotes
		let json = JSON.stringify(param)
		json = json.replace(/'/g, "''")
		return `'${json}'`
	}

	throw new Error(`Unsupported parameter type: ${typeof param}`)
}

//hide apy key
function hideMiddlePartOfString(apiKey, visibleStart = 2, visibleEnd = 4) {
	if (apiKey.length <= visibleStart + visibleEnd) {
		return apiKey; // If the API key is too short, return it as is
	}

	const firstVisiblePart = apiKey.slice(0, visibleStart);
	const lastVisiblePart = apiKey.slice(apiKey.length - visibleEnd);
	// const hiddenPartLength = apiKey.length - visibleStart - visibleEnd;
	// const hiddenPart = "*".repeat(hiddenPartLength);
	const hiddenPart = "*".repeat(4);

	return firstVisiblePart + hiddenPart + lastVisiblePart;
}


//function that convert bytes to GB
const bytesToGigabytes = (bytes, decimals = 2) => {
	if (!bytes) return 0;
	return parseFloat((bytes / 1073741824).toFixed(decimals));
}

//function that convert GB to bytes
const gigabytesToBytes = (gigabytes) => {
	if (!gigabytes) return 0;
	const bytes = gigabytes * 1073741824;
	return bytes;
}

//function that convert bytes
const formatBytes = (bytes, decimals = 2) => {
	if (!bytes) return '0 Bytes';

	const k = 1000;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


//cut string and add dots
const truncateString = (input, cutLength = "20") => input.length > cutLength ? `${input.substring(0, cutLength)}...` : input;


//return the size of string in bytes
//you can select two type of encoding
//UTF-16 or UTF-8
//https://dev.to/rajnishkatharotiya/get-byte-size-of-the-string-in-javascript-20jm (READ THE COMMENT TO THE ARTICLE)
const stringToByte = (str, encoding) => {
	let size;
	if (encoding == "UTF-16") size = str.length * 2;
	if (encoding == "UTF-8") size = new Blob([str]).size;
	return size;
}


//return tru o false based on the string is or not a valid slug
function isValidSlug(slug) {
	// Define a regular expression pattern for a valid slug
	const slugPattern = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

	// Use the test() method to check if the slug matches the pattern
	return slugPattern.test(slug);
}

//Fetch function used by Next.js Front-end to fetch next.js API route
//tech = "fetch" | "axios"
//onDownloadProgress, onUploadProgress, isBlob --> work only with tech === "axios"
const fetchApiRoute = async (opt) => {
	const { tech, method, endpoint, endpointCallLocation, queryStrings, body, auth, onDownloadProgress, onUploadProgress, isBlob = false } = opt;
	//select fetching library
	if (!tech) {
		tech = "fetch";
	}
	//check if queryStrings is different from undefined and is an array with length greater then 0
	//this means that there are query strings to be appended to the url
	if (queryStrings !== undefined && queryStrings.length > 0) {
		endpoint = endpoint + "?";
		queryStrings.forEach((query) => {
			endpoint = endpoint + "&" + query;
		})
	}
	//if auth is provived create authorization header
	let headers;
	if (auth) {
		if (tech === "fetch") {
			headers = new Headers();
			headers.append("Authorization", "Bearer " + auth);
		}
		if (tech === "axios") {
			headers = { "Authorization": "Bearer " + auth };
		}
	} else {
		if (tech === "fetch") {
			headers = new Headers();
		}
		if (tech === "axios") {
			headers = {};
		}
	}
	//build fetch obj paramters
	const fetchObj = {
		method: method,
		headers
	};
	//based on fetch method append body if present
	if (body) {
		const isBuffer = body instanceof ArrayBuffer;
		if (tech === "fetch") {
			fetchObj.body = isBuffer ? body : JSON.stringify(body);
			fetchObj.headers.append("Content-Type", isBuffer ? "application/octet-stream" : "application/json");
		}
		if (tech === "axios") {
			fetchObj.data = isBuffer ? body : JSON.stringify(body);
			fetchObj.headers["Content-Type"] = isBuffer ? "application/octet-stream" : "application/json";
		}
	}
	let res;
	if (tech === "fetch") {
		try {
			res = await fetch(endpoint, fetchObj);
		} catch (error) {
			res = {
				status: error.status ? error.status : 533,
				ok: false,
				data: {}
			}
			res.data.config = fetchObj;
			res.data.config.url = endpoint;
			res.data.code = error.code;
			res.data.message = error.message;
			res.data.name = error.name;
			res.data.detail = error.toString();
		}
	}
	if (tech === "axios") {
		fetchObj.url = endpoint;
		if (isBlob) {
			fetchObj.responseType = "blob";
		}
		try {
			if (method === "GET") {
				if (onDownloadProgress) {
					fetchObj.onDownloadProgress = onDownloadProgress;
				}
				res = await axios(fetchObj);
			}
			if (method === "POST" || method === "PATCH") {
				if (onUploadProgress) {
					fetchObj.onUploadProgress = onUploadProgress;
				}
				res = await axios(fetchObj);
			}
		} catch (error) {
			res = {
				status: error.response && error.response.status ? error.response.status : 533,
				data: error.response && error.response.data ? error.response.data : {}
			}
			res.data.config = error.config ? error.config : undefined;
			res.data.request = error.request ? error.request : undefined;
			res.data.statusText = error.response && error.response.statusText ? error.response.statusText : "";
			res.data.code = error.code;
			res.data.message = error.message;
			res.data.name = error.name;
		}
	}
	// If the status code is not in the range 200-299,
	// we still try to parse and throw it.
	let resOk = false;
	if (tech === "fetch") {
		resOk = res.ok;
	}
	if (tech === "axios") {
		resOk = res.status === 200 ? true : false;
	}
	if (!resOk) {
		let prefix = format(new Date(), 'yyyy/MM/dd HH:mm:ss.SSS');
		const error = new Error(prefix + ' - An error occurred while front-end directly fetches data - ' + endpointCallLocation);
		if (tech === "fetch") {
			try {
				error.info = await res.json();
			} catch (errorCatch) {
				//this is the case in which the fetch error is not handled by the backend or gateway
				error.info = res.data;
				error.status = res.status;
			}
			error.status = res.status;
			//if the request was a POST or a PATCH request the body of the request is added to the error to be sent via mail in the case it is not an error already handled by the backend 
			if (method === "POST" || method === "PATCH") {
				let stringBody = JSON.stringify(body);
				error.reqBody = stringBody
			}
		}
		if (tech === "axios") {
			error.info = res.data;
			error.status = res.status;
			//if the request was a POST or a PATCH request the body of the request is added to the error to be sent via mail in the case it is not an error already handled by the backend 
			if (method === "POST" || method === "PATCH") {
				let stringBody = JSON.stringify(body);
				error.reqBody = stringBody
			}
		}
		throw error
	}
	let contentType;
	if (tech === "fetch") {
		contentType = res.headers.get("Content-Type");
	}
	if (tech === "axios") {
		contentType = res.headers["content-type"];
	}
	//binary response?
	if (contentType === 'application/octet-stream' || contentType?.startsWith('image/')) {
		if (tech === "fetch") {
			var mime = 'application/octet-binary';
			let blob = new Blob([await res.arrayBuffer()], { type: mime });
			return { data: blob, headers: res.headers, config: { url: endpoint } }
		}
		if (tech === "axios") {
			function convertSQLiteStringToBlob(sqliteString) {
				// Remove escape sequences and split the string into bytes
				var bytes = sqliteString.replace(/\\x([0-9A-Fa-f]{2})/g, function (match, p1) {
					return String.fromCharCode(parseInt(p1, 16));
				}).split('').map(function (c) {
					return c.charCodeAt(0);
				});

				// Convert byte array to Uint8Array
				var uintArray = new Uint8Array(bytes);

				// Create Blob from Uint8Array
				var blob = new Blob([uintArray], { type: 'application/octet-stream' });

				return blob;
			}
			let blob;
			if (isBlob) {
				blob = res.data;
			} else {
				blob = convertSQLiteStringToBlob(res.data);
			}
			return { data: blob, headers: res.headers, request: res.request, config: res.config }
		}
	} else {
		if (tech === "fetch") {
			return res.json();
		}
		if (tech === "axios") {
			return res.data;
		}
	}
}

// Fetch function used by SWR hooks
// this fetch is design to throw error bacause it is what is request from SWR
const swrFetcher = async (url, component, queryStrings, auth) => {
	//check if queryStrings is different from undefined and is an array with lenght greater then 0
	//this means that there are query strings to be appended to the url
	if (queryStrings !== undefined && queryStrings.length > 0) {
		url = url + "?";
		queryStrings.forEach((query) => {
			url = url + "&" + query;
		})
	}
	let headers = new Headers();
	if (auth) {
		headers.append("Authorization", "Bearer " + auth);
	}

	const res = await fetch(url, {
		method: "GET",
		headers,
	});

	//if res.redirect is true a redirect is performed
	if (res.ok && res.redirected) {
		const json = await res.json();
		if (json.redirectToSignin) {
			//after succesfull log-out reset posthog
			posthog.reset();
			//redirect to sign-in page
			window.location.href = "/auth/sign-in";
		}
		return
	}

	//if res.ok is not true this mean that an error occured
	if (!res.ok && !res.redirected) {
		let prefix = format(new Date(), 'yyyy/MM/dd HH:mm:ss.SSS');
		const error = new Error(prefix + ' - An error occurred while SWR fetches data - ' + component);
		error.info = await res.json();
		error.status = res.status;
		throw error
	}
	//this is the case where everything is ok
	if (res.ok && !res.redirected) {
		const contentType = res.headers.get('Content-Type')
		//binary response?
		if (contentType === 'application/octet-stream' || contentType?.startsWith('image/')) {
			return { data: await res.arrayBuffer(), headers: res.headers }
		}
		const json = await res.json();
		return json;
	}
}

// GET Fetch function used by Next.js API routes
const apiGetApiFetcher = async (req, res, url) => {
	//this timeout is used to trigger an error before the backend timemouts
	var reachTimeout = false;
	const id = setTimeout(() => {
		reachTimeout = true;
		res.status(530).json(
			{
				name: "Timeout error",
				endpoint: url,
				message: "Server timedout handling your request."
			}
		)
	}, API_TIMEOUT);

	try {
		//extract from the request the httpOnly cookie where is stored the token
		const session = await getLoginSession(req);
		if (session !== undefined) {
			let headers = new Headers();
			headers.append("Authorization", "Bearer " + session);
			const startExecutionTime = performance.now();
			const response = await fetch(`${API_API_URL}${url}`, {
				method: "GET",
				headers,
			});
			const endExecutionTime = performance.now();
			const executionTime = endExecutionTime - startExecutionTime;
			if (!reachTimeout) {
				clearTimeout(id);
				const data = await response.json();
				data.executionTime = executionTime;
				if (response.status === 200) {
					res.status(200).json(data);
				} else {
					//analyze error already catched from backend
					logThis(`Error message from backend. Error status ${response.status}. Error message: ${data.message}`);
					if (response.status == 401) {
						res.redirect(307, '/api/logout');
					} else {
						res.status(response.status).json({
							name: "Backend error message",
							endpoint: url,
							message: data.message
						});
					}
				}
			}
		} else {
			// this is the case in which doesn't exist any token
			if (!reachTimeout) {
				clearTimeout(id);
				// return undefined;
				// if data is undefined means that a session cookie is not present
				res.redirect(307, '/api/logout');
			}
		}
	} catch (error) {
		console.log(error);
		clearTimeout(id);
		//something went wrong calling backend
		//analyze error not catched from backend
		logThis(`Error not catched from backend. Error name: ${error.name} | Error message: ${error.message}`);
		res.status(533).json({
			name: error.name,
			endpoint: url,
			// message: error.message
			message: JSON.stringify(error, Object.getOwnPropertyNames(error))
		})
	}
}


// GET Fetch function used by Next.js API routes
const dashboardGetApiFetcher = async (req, res, url) => {
	//this timeout is used to trigger an error before the backend timemouts
	var reachTimeout = false;
	const id = setTimeout(() => {
		reachTimeout = true;
		res.status(530).json(
			{
				name: "Timeout error",
				endpoint: url,
				message: "Server timedout handling your request."
			}
		)
	}, API_TIMEOUT);

	try {
		//extract from the request the httpOnly cookie where is stored the token
		const session = await getLoginSession(req);
		if (session !== undefined) {
			let headers = new Headers();
			const startExecutionTime = performance.now();
			headers.append("Authorization", "Bearer " + session);
			const response = await fetch(`${DASHBOARD_API_URL}${url}`, {
				method: "GET",
				headers,
			});
			const endExecutionTime = performance.now();
			const executionTime = endExecutionTime - startExecutionTime;
			if (!reachTimeout) {
				clearTimeout(id);
				// return await response.json();
				const data = await response.json();
				data.executionTime = executionTime;
				if (data.status === 200) {
					res.status(200).json(data);
				} else {
					//analyze error already catched from backend
					logThis(`Error message from backend. Error status ${data.status}. Error message: ${data.message}`);
					if (data.status == 401) {
						res.redirect(307, '/api/logout');
					} else {
						res.status(data.status).json({
							name: "Backend error message",
							endpoint: url,
							message: data.message
						});
					}
				}
			}
		} else {
			// this is the case in which doesn't exist any token
			if (!reachTimeout) {
				clearTimeout(id);
				// return undefined;
				// if data is undefined means that a session cookie is not present
				res.redirect(307, '/api/logout');
			}
		}
	} catch (error) {
		console.log(error);
		clearTimeout(id);
		//something went wrong calling backend
		//analyze error not catched from backend
		logThis(`Error not catched from backend. Error name: ${error.name} | Error message: ${error.message}`);
		res.status(533).json({
			name: error.name,
			endpoint: url,
			// message: error.message
			message: JSON.stringify(error, Object.getOwnPropertyNames(error))
		})
	}
}

// DELETE Fetch function used by Next.js API routes
const dashboardDeleteApiFetcher = async (req, res, url, body) => {
	//this timeout is used to trigger an error before the backend timemouts
	var reachTimeout = false;
	const id = setTimeout(() => {
		reachTimeout = true;
		res.status(530).json(
			{
				name: "Timeout error",
				endpoint: url,
				message: "Server timedout handling your request."
			}
		)
	}, API_TIMEOUT);

	try {
		//extract from the request the httpOnly cookie where is stored the token
		const session = await getLoginSession(req);
		if (session !== undefined) {
			let headers = new Headers();
			headers.append("Authorization", "Bearer " + session);
			const startExecutionTime = performance.now();
			let response;
			if (!body) {
				response = await fetch(`${DASHBOARD_API_URL}${url}`, {
					method: "DELETE",
					headers,
				});
			}
			if (body) {
				headers.append("Content-Type", "application/json");
				response = await fetch(`${DASHBOARD_API_URL}${url}`, {
					method: "DELETE",
					body: JSON.stringify(body),
					headers,
				});
			}
			const endExecutionTime = performance.now();
			const executionTime = endExecutionTime - startExecutionTime;
			if (!reachTimeout) {
				clearTimeout(id);
				// return await response.json();
				const data = await response.json();
				data.executionTime = executionTime;
				if (data.status === 200) {
					res.status(200).json(data);
				} else {
					//analyze error already catched from backend
					logThis(`Error message from backend. Error status ${data.status}. Error message: ${data.message}`);
					if (data.status == 401) {
						res.redirect(307, '/api/logout');
					} else {
						res.status(data.status).json({
							name: "Backend error message",
							endpoint: url,
							message: data.message
						});
					}
				}
			}
		} else {
			// this is the case in which doesn't exist any token
			if (!reachTimeout) {
				clearTimeout(id);
				// return undefined;
				// if data is undefined means that a session cookie is not present
				res.redirect(307, '/api/logout');
			}
		}
	} catch (error) {
		console.log(error);
		clearTimeout(id);
		//something went wrong calling backend
		//analyze error not catched from backend
		logThis(`Error not catched from backend. Error name: ${error.name} | Error message: ${error.message}`);
		res.status(533).json({
			name: error.name,
			endpoint: url,
			// message: error.message
			message: JSON.stringify(error, Object.getOwnPropertyNames(error))
		})
	}
}

// PUT Fetch function used by Next.js API routes
const dashboardPutApiFetcher = async (req, res, url, body) => {
	//this timeout is used to trigger an error before the backend timemouts
	var reachTimeout = false;
	const id = setTimeout(() => {
		reachTimeout = true;
		res.status(530).json(
			{
				name: "Timeout error",
				endpoint: url,
				message: "Server timedout handling your request."
			}
		)
	}, API_TIMEOUT);

	try {
		//extract from the request the httpOnly cookie where is stored the token
		const session = await getLoginSession(req);
		if (session !== undefined) {
			let headers = new Headers();
			headers.append("Authorization", "Bearer " + session);
			headers.append("Content-Type", "application/json");
			const startExecutionTime = performance.now();
			const response = await fetch(`${DASHBOARD_API_URL}${url}`, {
				method: "PUT",
				body: JSON.stringify(body),
				headers,
			});
			const endExecutionTime = performance.now();
			const executionTime = endExecutionTime - startExecutionTime;
			if (!reachTimeout) {
				clearTimeout(id);
				// return await response.json();
				const data = await response.json();
				data.executionTime = executionTime;
				if (data.status === 200) {
					res.status(200).json(data);
				} else {
					//analyze error already catched from backend
					logThis(`Error message from backend. Error status ${data.status}. Error message: ${data.message}`);
					if (data.status == 401) {
						res.redirect(307, '/api/logout');
					} else {
						res.status(data.status).json({
							name: "Backend error message",
							endpoint: url,
							message: data.message
						});
					}
				}
			}
		} else {
			// this is the case in which doesn't exist any token
			if (!reachTimeout) {
				clearTimeout(id);
				// return undefined;
				// if data is undefined means that a session cookie is not present
				res.redirect(307, '/api/logout');
			}
		}
	} catch (error) {
		console.log(error);
		clearTimeout(id);
		//something went wrong calling backend
		//analyze error not catched from backend
		logThis(`Error not catched from backend. Error name: ${error.name} | Error message: ${error.message}`);
		res.status(533).json({
			name: error.name,
			endpoint: url,
			// message: error.message
			message: JSON.stringify(error, Object.getOwnPropertyNames(error))
		})
	}
}

// POST Fetch function used by Next.js API routes
const dashboardPostApiFetcher = async (req, res, url, body) => {
	//this timeout is used to trigger an error before the backend timemouts
	var reachTimeout = false;
	const id = setTimeout(() => {
		reachTimeout = true;
		res.status(530).json(
			{
				name: "Timeout error",
				endpoint: url,
				message: "Server timedout handling your request."
			}
		)
	}, API_TIMEOUT);

	try {
		//extract from the request the httpOnly cookie where is stored the token
		const session = await getLoginSession(req);
		if (session !== undefined) {
			let headers = new Headers();
			headers.append("Authorization", "Bearer " + session);
			headers.append("Content-Type", "application/json");
			const startExecutionTime = performance.now();
			const response = await fetch(`${DASHBOARD_API_URL}${url}`, {
				method: "POST",
				body: JSON.stringify(body),
				headers
			});
			const endExecutionTime = performance.now();
			const executionTime = endExecutionTime - startExecutionTime;
			if (!reachTimeout) {
				clearTimeout(id);
				// return await response.json();
				const data = await response.json();
				data.executionTime = executionTime;
				if (data.status === 200) {
					res.status(200).json(data);
				} else {
					//analyze error already catched from backend
					logThis(`Error message from backend. Error status ${data.status}. Error message: ${data.message}`);
					if (data.status == 401) {
						res.redirect(307, '/api/logout');
					} else {
						res.status(data.status).json({
							name: "Backend error message",
							endpoint: url,
							message: data.message
						});
					}
				}
			}
		} else {
			// this is the case in which doesn't exist any token
			if (!reachTimeout) {
				clearTimeout(id);
				// return undefined;
				// if data is undefined means that a session cookie is not present
				res.redirect(307, '/api/logout');
			}
		}
	} catch (error) {
		console.log(error);
		clearTimeout(id);
		//something went wrong calling backend
		//analyze error not catched from backend
		logThis(`Error not catched from backend. Error name: ${error.name} | Error message: ${error.message}`);
		res.status(533).json({
			name: error.name,
			endpoint: url,
			// message: error.message
			message: JSON.stringify(error, Object.getOwnPropertyNames(error))
		})
	}
}

// PATCH Fetch function used by Next.js API routes
const dashboardPatchApiFetcher = async (req, res, url, body) => {
	//this timeout is used to trigger an error before the backend timemouts
	var reachTimeout = false;
	const id = setTimeout(() => {
		reachTimeout = true;
		res.status(530).json(
			{
				name: "Timeout error",
				endpoint: url,
				message: "Server timedout handling your request."
			}
		)
	}, API_TIMEOUT);
	try {
		//extract from the request the httpOnly cookie where is stored the token
		const session = await getLoginSession(req);
		if (session !== undefined) {
			let headers = new Headers();
			headers.append("Authorization", "Bearer " + session);
			const startExecutionTime = performance.now();
			let response;
			if (!body) {
				response = await fetch(`${DASHBOARD_API_URL}${url}`, {
					method: "PATCH",
					headers
				});
			}
			if (body) {
				headers.append("Content-Type", "application/json");
				response = await fetch(`${DASHBOARD_API_URL}${url}`, {
					method: "PATCH",
					body: JSON.stringify(body),
					headers
				});
			}
			const endExecutionTime = performance.now();
			const executionTime = endExecutionTime - startExecutionTime;
			if (!reachTimeout) {
				clearTimeout(id);
				// return await response.json();
				const data = await response.json();
				data.executionTime = executionTime;
				if (data.status === 200) {
					res.status(200).json(data);
				} else {
					//analyze error already catched from backend
					logThis(`Error message from backend. Error status ${data.status}. Error message: ${data.message}`);
					if (data.status == 401) {
						res.redirect(307, '/api/logout');
					} else {
						res.status(data.status).json({
							name: "Backend error message",
							endpoint: url,
							message: data.message
						});
					}
				}
			}
		} else {
			// this is the case in which doesn't exist any token
			if (!reachTimeout) {
				clearTimeout(id);
				// return undefined;
				// if data is undefined means that a session cookie is not present
				res.redirect(307, '/api/logout');
			}
		}
	} catch (error) {
		console.log(error);
		clearTimeout(id);
		//something went wrong calling backend
		//analyze error not catched from backend
		logThis(`Error not catched from backend. Error name: ${error.name} | Error message: ${error.message}`);
		res.status(533).json({
			name: error.name,
			endpoint: url,
			// message: error.message
			message: JSON.stringify(error, Object.getOwnPropertyNames(error))
		})
	}
}


//Build a new url with updating query string
const buildUrlFromActualQuery = (actualPathname, actualQuery, excludedQuery, updateQuery, newValue) => {
	//encode URL before appending as query string
	const ecodedNewValue = encodeURIComponent(newValue) //TOGLI
	let newUrl = `${actualPathname}?`;
	Object.entries(actualQuery).forEach(([key, value], index) => {
		if (!excludedQuery.includes(key)) {
			newUrl = `${newUrl}${key}=${value}&`
		}
	});
	if (Array.isArray(updateQuery) && Array.isArray(newValue) && updateQuery.length === newValue.length) {
		updateQuery.forEach((query, index) => {
			newUrl = `${newUrl}${query}=${newValue[index]}&`
		});
	} else {
		if (updateQuery && ecodedNewValue) {
			newUrl = `${newUrl}${updateQuery}=${ecodedNewValue}`
		}
	}
	//is new url end with ? or & remove last character
	newUrl = newUrl.replace(/[?&]$/, '');
	return newUrl;
}


//Generate random id
function generateRandomId() {
	// Generate a random number and convert it to base 36 (which includes digits and lowercase letters)
	const randomPart = Math.random().toString(36).substring(2, 11); // Using substring to remove "0." and keep 9 characters

	// Append the current timestamp (converted to base 36) to ensure uniqueness even if generated in rapid succession
	const timestampPart = Date.now().toString(36);

	// Combine both parts to create the random ID
	const randomId = randomPart + timestampPart;

	return randomId;
}

//remove from an array an element with a specific id
function removeObjectById(array, idToRemove) {
	return array.filter(item => item.id !== idToRemove);
}

//search from an array an element with a specific id
function hasObjectWithId(array, idToFind) {
	return array.some(item => item.id === idToFind);
}

//remove all map labels
function removeMapLabels() {
	// Select all elements with the class 'jvectormap-tip'
	const elementsToRemove = document.querySelectorAll('.jvectormap-tip');

	// Choose the element you want to keep (for example, the first one)
	const elementToKeep = elementsToRemove[elementsToRemove.length - 1];

	// Loop through each selected element and remove it except the one to keep
	elementsToRemove.forEach(element => {
		if (element !== elementToKeep) {
			element.remove();
		}
	});
}

function deepCopyMap(originalMap) {
	const newMap = new Map();
	originalMap.forEach((value, key) => {
		// If the value is an object or another map, recursively clone it
		const clonedValue = (typeof value === 'object' && value !== null) ? deepCopy(value) : value;
		// If the key is an object or another map, recursively clone it
		const clonedKey = (typeof key === 'object' && key !== null) ? deepCopy(key) : key;
		newMap.set(clonedKey, clonedValue);
	});
	return newMap;
}

function deepCopy(obj) {
	if (typeof obj !== 'object' || obj === null) {
		// If obj is not an object, return it as is
		return obj;
	}
	if (obj instanceof Map) {
		// If obj is a map, use deepCopyMap function to clone it
		return deepCopyMap(obj);
	}
	// If obj is an object, clone each property recursively
	const newObj = Array.isArray(obj) ? [] : {};
	for (let key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			newObj[key] = deepCopy(obj[key]);
		}
	}
	return newObj;
}

//validate password
function checkPassword(password) {
	var conditions = [
		/[A-Z]/, // Uppercase letter
		/[a-z]/, // Lowercase letter
		/\d/,    // Number
		/.{8,}/  // 8 characters minimum
	];

	var unmetConditions = [];

	conditions.forEach(function (condition, index) {
		if (!condition.test(password)) {
			switch (index) {
				case 0:
					unmetConditions.push("Uppercase letter is missing");
					break;
				case 1:
					unmetConditions.push("Lowercase letter is missing");
					break;
				case 2:
					unmetConditions.push("Number is missing");
					break;
				case 3:
					unmetConditions.push("Password must be at least 8 characters long");
					break;
			}
		}
	});

	return unmetConditions;
}


//convert a map of map into an object
function mapToObject(map) {
	const obj = {};
	for (const [key, value] of map) {
		// If the value is a Map, recursively convert it to an object
		obj[key] = value instanceof Map ? mapToObject(value) : value;
	}
	return obj;
}

//convert object into map of map
function objectToMap(obj) {
	const map = new Map();
	for (const [key, value] of Object.entries(obj)) {
		// Check if the value is a plain object (not null and not an array)
		if (value && typeof value === 'object' && !Array.isArray(value)) {
			map.set(key, objectToMap(value));
		} else {
			map.set(key, value);
		}
	}
	return map;
}


export {
	logThis,
	delay,
	isEmpty,
	getExecutionAmbient,
	disableSendMail,
	convertDateToUtc0,
	convertUtc0ToLocalTimeZone,
	compareDateToToday,
	getUtcOffset,
	validateString,
	validateEnvVariable,
	removeExtraSpaceAndTrimString,
	hashString,
	generateId,
	validateStringnonEncodingCharsInUrl,
	capitalizeFirstLetter,
	removeQueryString,
	hideMiddlePartOfString,
	formatBytes,
	bytesToGigabytes,
	gigabytesToBytes,
	truncateString,
	stringToByte,
	isValidSlug,
	fetchApiRoute,
	swrFetcher,
	apiGetApiFetcher,
	dashboardGetApiFetcher,
	dashboardDeleteApiFetcher,
	dashboardPutApiFetcher,
	dashboardPostApiFetcher,
	dashboardPatchApiFetcher,
	escapeParameter,
	buildUrlFromActualQuery,
	generateRandomId,
	removeObjectById,
	hasObjectWithId,
	removeMapLabels,
	checkPassword,
	deepCopyMap,
	deepCopy,
	mapToObject,
	objectToMap,
}