const typography = {
	fontFamily: [
		"SwissIntl",
		"-apple-system",
		"BlinkMacSystemFont",
		'"Segoe UI"',
		"Roboto",
		"Roboto Mono",
		'"Helvetica Neue"',
		"Arial",
		"sans-serif",
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(","),
	root: {
		textDecoration: "underline"
	},
	fontSize: 13,
	fontWeightLight: 300,
	fontWeightRegular: 400,
	fontWeightMedium: 500,
	fontWeightBold: 600,

	'11px-reg': {
		fontSize: '0.0,6875rem',
		lineHeight: '0.983125rem',
		letterSpacing: '0.010625rem',
		fontWeight: '400',
	},
	'11px-med': {
		fontSize: '0.6875rem',
		lineHeight: '0.983125rem',
		letterSpacing: '0.010625rem',
		fontWeight: '500',
	},
	'11px-demi': {
		fontSize: '0.6875rem',
		lineHeight: '0.983125rem',
		letterSpacing: '0.010625rem',
		fontWeight: '600',
	},
	'12px-light': {
		fontSize: '0.75rem',
		lineHeight: '0.75rem',
		letterSpacing: '0',
		fontWeight: '300',
	},
	'12px-book': {
		fontSize: '0.75rem',
		lineHeight: '1.5rem',
		letterSpacing: '0.010625rem',
		fontWeight: '350',
	},
	'12px-reg': {
		fontSize: '0.75rem',
		lineHeight: '1.5rem',
		letterSpacing: '0.010625rem',
		fontWeight: '400',
	},
	'12px-med': {
		fontSize: '0.75rem',
		lineHeight: '1.5rem',
		letterSpacing: '0.010625rem',
		fontWeight: '500',
	},
	'12px-demi': {
		fontSize: '0.75rem',
		lineHeight: '1.5rem',
		letterSpacing: '0.010625rem',
		fontWeight: '600',
	},
	'13px-light': {
		fontSize: '0.8125rem',
		lineHeight: '0.875rem',
		letterSpacing: '0',
		fontWeight: '300',
	},
	'14px-light': {
		fontSize: '0.875rem',
		lineHeight: '1.3215rem',
		letterSpacing: '0',
		fontWeight: '300',
	},
	'14px-book': {
		fontSize: '0.875rem',
		lineHeight: '0.875rem',
		letterSpacing: '0',
		fontWeight: '350',
	},
	'14px-reg': {
		fontSize: '0.875rem',
		lineHeight: '0.875rem',
		letterSpacing: '0',
		fontWeight: '400',
	},
	'14px-med': {
		fontSize: '0.875rem',
		lineHeight: '0.875rem',
		letterSpacing: '0',
		fontWeight: '500',
	},
	'14px-demi': {
		fontSize: '0.875rem',
		lineHeight: '120%',
		letterSpacing: '0',
		fontWeight: '600',
	},
	'14px-bold': {
		fontSize: '0.875rem',
		lineHeight: '0.875rem',
		letterSpacing: '0',
		fontWeight: '700',
	},
	'15px-light': {
		fontSize: '0.9375rem',
		lineHeight: '1.375rem',
		letterSpacing: '0',
		fontWeight: '300',
	},
	'15px-reg': {
		fontSize: '0.9375rem',
		lineHeight: '1.375rem',
		letterSpacing: '0',
		fontWeight: '400',
	},
	'15px-med': {
		fontSize: '0.9375rem',
		lineHeight: '1.375rem',
		letterSpacing: '0',
		fontWeight: '500',
	},
	'15px-bold': {
		fontSize: '0.9375rem',
		lineHeight: '1.375rem',
		letterSpacing: '0',
		fontWeight: '700',
	},
	'16px-light': {
		fontSize: '1rem',
		lineHeight: 'normal',
		letterSpacing: '0',
		fontWeight: '300',
	},
	'16px-reg': {
		fontSize: '1rem',
		lineHeight: 'normal',
		letterSpacing: '0',
		fontWeight: '400',
	},
	'16px-med': {
		fontSize: '1rem',
		lineHeight: 'normal',
		letterSpacing: '0',
		fontWeight: '500',
	},
	'16px-bold': {
		fontSize: '1rem',
		lineHeight: 'normal',
		letterSpacing: '0',
		fontWeight: '700',
	},
	'17px-light': {
		fontSize: '1.0625rem',
		lineHeight: 'normal',
		letterSpacing: '0',
		fontWeight: '300',
	},
	'17px-book': {
		fontSize: '1.0625rem',
		lineHeight: 'normal',
		letterSpacing: '0',
		fontWeight: '350',
	},
	'17px-reg': {
		fontSize: '1.0625rem',
		lineHeight: 'normal',
		letterSpacing: '0',
		fontWeight: '400',
	},
	'17px-med': {
		fontSize: '1.0625rem',
		lineHeight: 'normal',
		letterSpacing: '0',
		fontWeight: '500',
	},
	'17px-bold': {
		fontSize: '1.0625rem',
		lineHeight: 'normal',
		letterSpacing: '0',
		fontWeight: '700',
	},
	'18px-light': {
		fontSize: '1.125rem',
		lineHeight: 'normal',
		letterSpacing: '0',
		fontWeight: '300',
	},
	'18px-reg': {
		fontSize: '1.125rem',
		lineHeight: 'normal',
		letterSpacing: '0',
		fontWeight: '400',
	},
	'18px-med': {
		fontSize: '1.125rem',
		lineHeight: 'normal',
		letterSpacing: '0',
		fontWeight: '500',
	},
	'18px-bold': {
		fontSize: '1.125rem',
		lineHeight: '1.875rem',
		letterSpacing: '0',
		fontWeight: '700',
	},
	'20px-light': {
		fontSize: '1.25rem',
		lineHeight: '1.875rem',
		letterSpacing: '0',
		fontWeight: '300',
	},
	'20px-reg': {
		fontSize: '1.25rem',
		lineHeight: '1.875rem',
		letterSpacing: '0',
		fontWeight: '400',
	},
	'20px-med': {
		fontSize: '1.25rem',
		lineHeight: '1.875rem',
		letterSpacing: '0',
		fontWeight: '500',
	},
	'20px-bold': {
		fontSize: '1.25rem',
		lineHeight: 'normal',
		letterSpacing: '0',
		fontWeight: '700',
	},
	'23px-reg': {
		fontSize: '1.4375rem',
		lineHeight: 'normal',
		letterSpacing: '0',
		fontWeight: '400',
	},
	'23px-med': {
		fontSize: '1.4375rem',
		lineHeight: 'normal',
		letterSpacing: '0',
		fontWeight: '500',
	},
	'23px-bold': {
		fontSize: '1.4375rem',
		lineHeight: 'normal',
		letterSpacing: '0',
		fontWeight: '700',
	},
	'53px-thin': {
		fontSize: '3.3125rem',
		lineHeight: '4.1075rem',
		letterSpacing: '0',
		fontWeight: '100',
	},
	'body-mob': {
		fontSize: '1rem',
		lineHeight: '1.5rem',
		letterSpacing: '0',
		fontWeight: '300',
	},
	'body-desk': {
		fontSize: '1.25rem',
		lineHeight: '1.875rem',
		letterSpacing: '0',
		fontWeight: '300',
	},
	'h1-desk': {
		fontSize: '7.625rem',
		lineHeight: '9.15rem',
		letterSpacing: '-0.22875rem',
		fontWeight: '100',
	},
	'h1-mob': {
		fontSize: '3.4375rem',
		lineHeight: '4.125rem',
		letterSpacing: '-0.103125rem',
		fontWeight: '100',
	},
	'h2-desk': {
		fontSize: '5rem',
		lineHeight: '6.5rem',
		letterSpacing: '-0.1rem',
		fontWeight: '200',
	},
	'h2-mob': {
		fontSize: '2.5rem',
		lineHeight: '3.25rem',
		letterSpacing: '-0.05rem',
		fontWeight: '200',
	},
	'h3-desk': {
		fontSize: '3rem',
		lineHeight: '3rem',
		letterSpacing: '0',
		fontWeight: '300',
	},
	'h3-mob': {
		fontSize: '2.375rem',
		lineHeight: '2.6125rem',
		letterSpacing: '0rem',
		fontWeight: '300',
	},
	'h4-desk': {
		fontSize: '2rem',
		lineHeight: 'normal',
		letterSpacing: '0',
		fontWeight: '300',
	},
	'h4-mob': {
		fontSize: '1.75rem',
		lineHeight: 'normal',
		letterSpacing: '0rem',
		fontWeight: '300',
	},
	'h5-desk': {
		fontSize: '1.5rem',
		lineHeight: '2.1rem',
		letterSpacing: '0',
		fontWeight: '300',
	},
	'h5-mob': {
		fontSize: '1.25rem',
		lineHeight: '1.75rem',
		letterSpacing: '0rem',
		fontWeight: '300',
	},
	h1: {
		fontSize: "2rem",
		fontWeight: 600,
		lineHeight: 1.25,
	},
	h2: {
		fontSize: "1.75rem",
		fontWeight: 600,
		lineHeight: 1.25,
	},
	h3: {
		fontSize: "1.5rem",
		fontWeight: 600,
		lineHeight: 1.25,
	},
	h4: {
		fontSize: "1.125rem",
		fontWeight: 500,
		lineHeight: 1.25,
	},
	h5: {
		fontSize: "1.0625rem",
		fontWeight: 500,
		lineHeight: 1.25,
	},
	h6: {
		fontSize: "1rem",
		fontWeight: 500,
		lineHeight: 1.25,
	},
	body1: {
		fontSize: 13
	},
	button: {
		textTransform: "none",
	},
};

export default typography;
