//react
import { useState } from 'react';
//sqlitecloud hooks
import { useSetError } from "@custom-hooks/useSetError";


const useCopyToClipboard = () => {
	const [copiedToClipboard, setCopiedToClipboard] = useState(false);
	const [error, setError] = useState(null);
	useSetError(error);

	const copyToClipboard = (value) => {
		try{
			navigator.clipboard.writeText(value);
			setCopiedToClipboard(true);
			setTimeout(() => {
				setCopiedToClipboard(false);
			}, [1000])
		} catch(error){
			error.status = 400;
			setError(error);
		}
	}
	return { copyToClipboard, copiedToClipboard };
}

export { useCopyToClipboard }