//react
import React, { useState, useEffect, useContext } from "react";
import { CacheProvider } from "@emotion/react";
//next.js
import { useRouter } from 'next/router';
//helmet
import { HelmetProvider, Helmet } from "react-helmet-async";
//posthog
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
//formbricks
import formbricks from "@formbricks/js";
//plausible
import PlausibleProvider from 'next-plausible';
//swr
import { SWRConfig } from 'swr';
//mui
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
//other css
import "./style.css"
import "@vendor/jvectormap.css";
import "@vendor/perfect-scrollbar.css";
// import "@fullcalendar/common/main.css";
// import "@fullcalendar/daygrid/main.css";
//localization
import "@i18n";
//sqlitecloud theme
import createTheme from "@theme";
import { ThemeProvider } from "@context/ThemeContext";
import useTheme from "@custom-hooks/useTheme";
import createEmotionCache from "@lib/createEmotionCache";
const clientSideEmotionCache = createEmotionCache();
//sqlitecloud libs
import analyzeError from "@lib/analyzeError";
//sqlitecloud context
import { StateProvider, StateContext } from "@context/StateContext";
//sqlitecloud componenets
import ErrorBoundary from "@generic-components/ErrorBoundary";
//sqlitecloud hook
import { useSetError } from "@custom-hooks/useSetError";

//check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
	//init posthog
	posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
		api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
		// Enable debug mode in development
		loaded: (posthog) => {
			if (process.env.NODE_ENV === 'development') posthog.debug()
		}
	});
}

function App({ Component, emotionCache = clientSideEmotionCache, pageProps }) {
	//get router
	const router = useRouter();
	//get theme
	const { theme } = useTheme();
	//get layout component
	const getLayout = Component.getLayout ?? ((page) => page);
	//handle error
	const [error, setError] = useState(null);
	useSetError(error);
	//get actual user from state context	
	const { userInfo: user, setFormbricksInit } = useContext(StateContext);
	//posthog identify user using email address
	//formbricks init and identify user using email address
	useEffect(() => {
		const formbricksSetUserId = async () => {
			try {
				formbricks.init({
					environmentId: process.env.NEXT_PUBLIC_FORMBRICKS_ENV_ID,
					apiHost: process.env.NEXT_PUBLIC_FORMBRICKS_API_HOST,
					userId: user.email,
					debug: process.env.NODE_ENV === 'development' ? true : false
				});
				setFormbricksInit(true);
			} catch (error) {
				console.log(error);
			}
		}
		if (user && user.email) {
			posthog.identify(user.email);
			formbricksSetUserId();
		}
	}, [user]);
	//posthog track page views
	useEffect(() => {
		const handleRouteChange = () => posthog?.capture('$pageview');
		router.events.on('routeChangeComplete', handleRouteChange);
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		}
	}, []);
	//render
	return (
		<PostHogProvider client={posthog}>
			<PlausibleProvider domain="dashboard.sqlitecloud.io">
				<CacheProvider value={emotionCache}>
					<HelmetProvider>
						<Helmet
							titleTemplate="%s"
							defaultTitle="SQLite Cloud Dashboard"
						/>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<MuiThemeProvider theme={createTheme(theme)}>
								<ErrorBoundary>
									<SWRConfig
										value={{
											onError: (error, key) => {
												const result = analyzeError(error);
												if (result.reportError) {
													setError(error);
												}
											},
											onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
												const result = analyzeError(error);
												// Only retry up to 10 times.
												if (!result.retry || retryCount >= 10) return
												// Retry after 5 seconds.
												setTimeout(() => revalidate({ retryCount }), 3000)
											}
										}}>
										{getLayout(<Component {...pageProps} />)}
									</SWRConfig>
								</ErrorBoundary>
							</MuiThemeProvider>
						</LocalizationProvider>
					</HelmetProvider>
				</CacheProvider>
			</PlausibleProvider>
		</PostHogProvider>
	);
}

const withThemeProvider = (Component) => {
	const AppWithThemeProvider = (props) => {
		return (
			<ThemeProvider>
				<StateProvider>
					<Component {...props} />
				</StateProvider>
			</ThemeProvider>
		);
	};
	AppWithThemeProvider.displayName = "AppWithThemeProvider";
	return AppWithThemeProvider;
};

export default withThemeProvider(App);
